export default {
  allow: [
    ...process.env.ROBOTS_ALLOW?.split('|') || [],
    '*?amp*',
    '*?amp%3Brefresh*',
    '*?fresh*',
    '*?menu=*',
    '*?p=*',
    '*?page*',
    '*?rating=*',
    '*?refresh*',
    '*?refreshing*',
    '*?ruleid*',
    '*?sku=*',
    '*&menu=*',
    '*&rating=*',
    '/_i/*',
    '/i18n/*',
  ],
  disallow: [
    ...process.env.ROBOTS_DISALLOW?.split('|') || [],
    '/*?*',
    '*/_e*',
    '*/graphql*',
    '/checkout/*',
    '/preferiti$',
    '/my-account$',
    '/my-account/i-miei-ordini$',
    '/my-account/modifica$',
    '/my-account/le-mie-offerte$',
    '/my-account/i-miei-preferiti$',
    '/my-account/notifiche-attive$',
  ],
}
